import React, { useEffect, useState } from "react";

import AFRAME from 'aframe';
import { Entity } from "aframe-react";
import Controles from "./controles";

export default function Scene(){
    const [elemento, setElemento] = useState(null);
    const [selectedElemento, setSelectedElemento] = useState(null);
    const [objetos, setObjetos] = useState([]);
    const [newObjeto, setNewObjeto] = useState(null);
    const [ambiente, setAmbiente] = useState('./Comedor.jpg');
    const [point, setPoint] = useState({x: 0, y:0});
    const [move, setMove] = useState(false);
    
    const seleccionar = (e) => {
        console.log('al seleccionar ', objetos)
        setElemento(e.id);
        //setSelectedElemento(e.srcElement.id)
    }

    const selectObject = (id) => {
        console.log(id)
        setNewObjeto(id);
    }

    const insertObject = (e) => {
        //console.log(e.target.tagName)
        if (e.target.tagName == 'A-ENTITY') seleccionar(e.target)
        else if (e.target.tagName == 'CANVAS'){                
                /*let cursor = document.querySelector('a-cursor');
                let intersection = cursor.components.raycaster.getIntersection(cursor.components.intersectedEl);
                console.log(intersection)*/                
                let position = {x:0, y:0, z:-1}
                if (!newObjeto) return;
                
                let scale = {x:1, y:1, z:1};
                if (newObjeto == './sofas/sillon1') scale = {x: 0.01, y: 0.01, z: 0.01}

                setObjetos([...objetos, {
                        id: (new Date()).getTime(),
                        position: position,
                        rotation: {x:0, y:0, z:0},
                        scale: scale,
                        model: newObjeto
                }])
                setNewObjeto(null);
        }
    }

    const updateObject = (elemento, atributos) => {
        
        let aux = objetos;
        let indice = aux.findIndex(x => x.id == elemento);
        aux[indice].position = atributos.elPosition;
        aux[indice].rotation = atributos.elRotation;
        aux[indice].scale = atributos.elTamanio;
                         
        setObjetos(aux)
    }

    const onTouchStart = (e) => {
        setPoint({x:e.touches[0].clientX, y:e.touches[0].clientY});
        setMove(false);
    }

    const onTouchMove = (e) => {
        if (e.touches[0].clientX != point.x || e.touches[0].clientY != point.y) setMove(true);
    }

    const onTouchEnd = (e) => {
        if (!move) insertObject(e)
        else setElemento(null)
    }

    return (<>
        <label>{elemento}</label>
        <Controles elemento={elemento} selectObject={selectObject} updateObject={updateObject} setAmbiente={setAmbiente}/>
        <a-scene onClick={insertObject} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
            <a-assets>
                  
            </a-assets>        
            <a-sky src={ambiente}>
                {objetos.map((o, k) => {
                    return <Entity id={o.id}
                                   key={k}
                                   className="objeto" 
                                   position={o.position}
                                   scale={o.scale} 
                                   rotation={o.rotation}
                                   gltf-model={o.model+'.glb'}
                                   events={{
                                        //click: seleccionar,						
                                   }}/>
                    })}
                
            </a-sky>
            <a-camera>
                <a-cursor position="0 0 -1" 
                          id="cursor"    
                          visible={false}                      
                          cursor="rayOrigin: mouse; fuse: true; fuseTimeout: 100"						  
                          raycaster="objects: .objeto"				  
                          material="shader:flat; color:white"/>
            </a-camera>
        </a-scene>
    </>);
}