import { Button, Card, Slider, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import './estilos.css'

export default function Controles({ elemento, selectObject, updateObject, setAmbiente }){
    const [title, setTitle] = useState(null);
    const [elPosition, setElPosition] = useState({x: 0, y: 0, z: 0})
    const [elRotation, setElRotation] = useState({x: 0, y: 0, z: 0})
    const [elTamanio, setElTamanio] = useState(1);
    const [activeTabKey, setActiveTabKey] = useState(1);
    //const [miElement, setMiElement] = useState(elemento)

    const setTamanio = (e) => {
        let el = document.getElementById(elemento);
        if (el){
            console.log(e)
            let v = e/100
            console.log(v)
            el.setAttribute('scale', `${v} ${v} ${v}`)
            setElTamanio(e);            
        }
    }

    const setPostition = (e) => {
        let el = document.getElementById(elemento);
        if (el){
            console.log(e)
            el.setAttribute('position', `${e.x} ${e.y} ${e.z}`)
            setElPosition(e)
        }
    }

    const setRotation = (e) => {
        let el = document.getElementById(elemento);
        if (el){
            console.log(e)
            el.setAttribute('rotation', `${e.x} ${e.y} ${e.z}`)
            setElRotation(e)            
        }
    }    

    const cambiarTabKey = (tk) => {
        setActiveTabKey(tk);
    }  

    const capturar = () => {
        document.querySelector('a-scene').components.screenshot.capture('perspective')
    }


    const setOpaco = (o) => {
        let cc = document.getElementById('controlesCard');

        if (o){
            cc.classList.add('opaco');
            cc.classList.remove('trans');
        }else{
            cc.classList.add('trans');
            cc.classList.remove('opaco');
        }        
    }

    const ambientes = [
        {
            id:0,
            key:0,
            fondo:'./Comedor.jpg'        
        },
        {
            id:1,
            key:1,
            fondo:'./foto2.jpg'
        },
        {
            id:2,
            key:2,
            fondo:'./room.jpg'
        }
    ].map(o => <>
                        <div key={o.id} style={{
                            
                            }}>
                            <input onChange={()=>setAmbiente(o.fondo)} type="radio" name="experiencia" id={o.id} style={{display:'none'}} value={o.id}/>
                            <label htmlFor={o.id}><img alt="-" src={o.fondo} width="50%" style={{display: 'block',
                                                                                                      marginLeft: 'auto',
                                                                                                      marginRight: 'auto',
                                                                                                      width: '50%'}}/></label>
                        </div>
                      </>)

    const objetos = [
        {
            key:1,
            label: 'Sofás',
            children: [
                        {
                            id:'sofa1',
                            img:'./sofas/sillon1',
                            scale:{x: 0.2, y: 0.2, z: 0.2}
                        },
                        {
                            id:'nova',
                            img:'./sofas/nova'
                        },
                        {
                            id:'australian_sofa',
                            img:'./sofas/australian_sofa'
                        },
                        {
                            id:'apollo_3_seat',
                            img:'./sofas/apollo_3_seat'
                        },
                       ].map(o => <>
                                    <div style={{
                                        
                                        }}>
                                        <input onChange={()=>selectObject(o.img)} type="radio" name="experiencia" id={o.id} style={{display:'none'}} value={o.id}/>
                                        <label htmlFor={o.id}><img alt="-" src={o.img+'.png'} width="50%" style={{display: 'block',
                                                                                                                  marginLeft: 'auto',
                                                                                                                  marginRight: 'auto',
                                                                                                                  width: '50%'}}/></label>
                                    </div>
                                  </>)
        },
        {
            key:2,
            label: 'Sillas',
            children: [
            {
                id:'woven_wishbone_kitchen_chair',
                img:'./sillas/woven_wishbone_kitchen_chair'
            },
            {
                id:'armchair',
                img:'./sillas/armchair'
            },
            {
                id:'chair',
                img:'./sillas/chair'
            },
           ].map(o => <>
                        <div style={{
                            
                            }}>
                            <input onChange={()=>selectObject(o.img)} type="radio" name="experiencia" id={o.id} style={{display:'none'}} value={o.id}/>
                            <label htmlFor={o.id}><img alt="-" src={o.img+'.png'} width="50%" style={{display: 'block',
                                                                                                      marginLeft: 'auto',
                                                                                                      marginRight: 'auto',
                                                                                                      width: '50%'}}/></label>
                        </div>
                      </>)
        },
        {
            key:3,
            label: 'Mesas',
            children: [
                {
                    id:'mesa_jantar_alba_vidro_redonda_drio_agave_casa',
                    img:'./mesas/mesa_jantar_alba_vidro_redonda_drio_agave_casa'
                },
                {
                    id:'classic_round_side_table',
                    img:'./mesas/classic_round_side_table'
                },
                {
                    id:'halva_coffee_table_oak_and_marble',
                    img:'./mesas/halva_coffee_table_oak_and_marble'
                },
                {
                    id:'jenson_compact_sideboard_solid_oak',
                    img:'./mesas/jenson_compact_sideboard_solid_oak'
                },
                {
                    id:'mesa_jantar_orlando_brazil_contemporaneo_agave',
                    img:'./mesas/mesa_jantar_orlando_brazil_contemporaneo_agave'
                },
               ].map(o => <>
                            <div style={{
                                
                                }}>
                                <input onChange={()=>selectObject(o.img)} type="radio" name="experiencia" id={o.id} style={{display:'none'}} value={o.id}/>
                                <label htmlFor={o.id}><img alt="-" src={o.img+'.png'} width="50%" style={{display: 'block',
                                                                                                          marginLeft: 'auto',
                                                                                                          marginRight: 'auto',
                                                                                                          width: '50%'}}/></label>
                            </div>
                          </>)
        },
        {
            key:4,
            label: 'Otros',
            children: ''
        },
    ];

    useEffect(() => {
        console.log('el', elemento)
        if (elemento) {
            let el = document.getElementById(elemento);
            setTitle('Controles');        
            let pos = el.getAttribute('position');
            let rot = el.getAttribute('rotation');
            let scl = el.getAttribute('scale').x;
            setOpaco(true);
            setElPosition(pos);
            setElRotation(rot);
            setElTamanio(scl*100);
            cambiarTabKey(2);
            /*let pos = el.getAttribute('position');
            setElPosition({x: pos.x, y: pos.y, z: pos.z})        */
        }else setOpaco(false);
        
    }, [elemento]);


    return (<>
        <Card title={title}
              size="small"
              className="trans"
              id="controlesCard"
              style={{
                     position: 'absolute',
                     zIndex: 1000,
                     width: 300,
                     height: 500, 
                     textAlign: 'left',
                     marginTop: 10,
                     marginLeft: 10,
                     overflow: 'auto',
              }}
              onMouseEnter={() => setOpaco(true)}
              onMouseLeave={() => setOpaco(false)}
              >
            <Tabs defaultActiveKey="1" items={[
                {
                    key:0,
                    label: 'Ambiente',
                    children: <>{ambientes}<Button type="primary" success style={{width:'100%'}} onClick={capturar}>Capturar</Button></>
                },
                {
                    key:1,
                    label: 'Objetos',
                    children: <>
                    <Tabs
                         onChange={null}
                         type="card"                         
                         items={objetos}
                    />            
                    
                    </>
                },
                {
                    key:2,
                    label: 'Personalizar',
                    children: <>
                    <Card title='Tamaño' size="small">
                        <><label>Factor:</label><Slider defaultValue={1} max={400} min={1} value={elTamanio} onChangeComplete={(e)=>updateObject(elemento, {elTamanio: {x: e/100, y: e/100, z: e/100}, elPosition, elRotation})} onChange={(e) => setTamanio(e)} /></>                
                    </Card>
                    <Card title='Posicion' size="small">
                        <><label>X:</label><Slider defaultValue={0} step={0.01} max={50} min={-50} value={elPosition.x} onChangeComplete={(e)=>updateObject(elemento, {elTamanio: {x: elTamanio/100, y: elTamanio/100, z: elTamanio/100}, elPosition: {...elPosition, x: e}, elRotation})} onChange={(e) => setPostition({...elPosition, x: e})} /></>
                        <><label>Y:</label><Slider defaultValue={0} step={0.01} max={50} min={-50} value={elPosition.y} onChangeComplete={(e)=>updateObject(elemento, {elTamanio: {x: elTamanio/100, y: elTamanio/100, z: elTamanio/100}, elPosition: {...elPosition, y: e}, elRotation})} onChange={(e) => setPostition({...elPosition, y: e})} /></>
                        <><label>Z:</label><Slider defaultValue={0} step={0.01} max={50} min={-50} value={elPosition.z} onChangeComplete={(e)=>updateObject(elemento, {elTamanio: {x: elTamanio/100, y: elTamanio/100, z: elTamanio/100}, elPosition: {...elPosition, z: e}, elRotation})} onChange={(e) => setPostition({...elPosition, z: e})} /></>
                    </Card>
                    <Card title='Rotacion' size="small">
                        <><label>X:</label><Slider defaultValue={0} step={0.01} max={180} min={-180} value={elRotation.x} onChangeComplete={(e)=>updateObject(elemento, {elTamanio: {x: elTamanio/100, y: elTamanio/100, z: elTamanio/100}, elPosition, elRotation: {...elRotation, x: e}})} onChange={(e) => setRotation({...elRotation, x: e})} /></>
                        <><label>Y:</label><Slider defaultValue={0} step={0.01} max={180} min={-180} value={elRotation.y} onChangeComplete={(e)=>updateObject(elemento, {elTamanio: {x: elTamanio/100, y: elTamanio/100, z: elTamanio/100}, elPosition, elRotation: {...elRotation, y: e}})} onChange={(e) => setRotation({...elRotation, y: e})} /></>
                        <><label>Z:</label><Slider defaultValue={0} step={0.01} max={180} min={-180} value={elRotation.z} onChangeComplete={(e)=>updateObject(elemento, {elTamanio: {x: elTamanio/100, y: elTamanio/100, z: elTamanio/100}, elPosition, elRotation: {...elRotation, z: e}})} onChange={(e) => setRotation({...elRotation, z: e})} /></>
                    </Card></>
                },

            ]} activeKey={activeTabKey} onChange={cambiarTabKey} />              
           
        </Card>
        
        <div >
            
        </div>
    </>)
}